// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-changelog-page-js": () => import("./../src/components/changelog-page.js" /* webpackChunkName: "component---src-components-changelog-page-js" */),
  "component---src-components-language-redirect-page-js": () => import("./../src/components/language-redirect-page.js" /* webpackChunkName: "component---src-components-language-redirect-page-js" */),
  "component---src-components-new-docs-redirect-page-js": () => import("./../src/components/new-docs-redirect-page.js" /* webpackChunkName: "component---src-components-new-docs-redirect-page-js" */),
  "component---src-components-page-with-navigation-js": () => import("./../src/components/page-with-navigation.js" /* webpackChunkName: "component---src-components-page-with-navigation-js" */)
}

